<template>
    <div>
        <StickyTable
            :title="$t('cp__respondents_list_table_title')"
            :columns="columns"
            sortable
            :empty="data.data.length === 0"
            :sortableColumns="columnNames"
            :currentPage="data.currentPage"
            :currentSorted="data.currentSorted"
            :currentSortDirection="data.currentSortDirection"
            :searchable="true"
            :searchableColumns="columnNames"
            :searching="data.searchTerm !== null"
            :searchbarPlaceholder="$t('cp__respondents_list_search')"
            :paginate="data.pages > 1 || (data.currentPage === 1 && data.itemCount > 10)"
            :defaultPaginationSize="data.paginationSize"
            :pages="data.pages"
            :selectHeaderCheckbox="data.numberOfSelectedItems > 0"
            :partiallySelected="data.numberOfSelectedItems > 0 && !data.allSelected"
            :filteredItemsSelected="data.allFilteredSelected"
            :editHeaderTooltip="$t('cp__respondents_list_edit_header_change_fields_tooltip')"
            :loading="headerLoading"
            :rowsPerPageText="$t('cp_table_rows_per_page')"
            @sort="sort($event)"
            @search="search($event)"
            @pageChange="setPage($event)"
            @selectAll="selectAll($event)"
            @editHeader="popup = popupOptions.EDIT_COLUMNS"
            @onPaginationSizeChange="setPaginationSize($event)"
        >
            <template v-slot:top-actions>
                <button
                    type="button"
                    class="action_button_blue"
                    @click="popup = popupOptions.ADD_RESPONDENT"
                >
                    <span>{{ $t("cp__respondents_list_add_respondents") }}</span>
                    <font-awesome-icon :icon="['far', 'plus']" />
                </button>
            </template>
            <StickyTableRow
                v-for="(row, index) in data.data"
                v-bind:key="index"
                :active="row.isSelected"
                bordered
                :errorLight="
                    row.error &&
                    (row.error.errorCode === invalidCodes.DUPLICATE ||
                        row.error.errorCode === invalidCodes.QUEUED)
                "
                :errorDark="row.error && row.error.errorCode === invalidCodes.INVALID"
                :tooltip="$t(getTooltip(row.error))"
            >
                <StickyTableCell first><Checkbox v-model="row.isSelected" /></StickyTableCell>
                <StickyTableCell
                    v-for="(column, index) in columns"
                    v-bind:key="index"
                    :isInvalid="column.property === 'email' && row.error && !row.error.valid"
                    :invalidMessage="
                        column.isRequired && !getDisplayName(column, row)
                            ? $t('cp__respondents_list_error_missing_value')
                            : ''
                    "
                >
                    {{ getDisplayName(column, row) }}
                </StickyTableCell>
                <StickyTableCell last>
                    <div class="controls">
                        <IconButton
                            label=""
                            v-tippy
                            class="controls-btn controls-btn-sneaky"
                            :title="previewInvitationTooltip(row)"
                            @show.native="() => !!previewInvitationTooltip(row)"
                            @click.native="previewInvitation(row)"
                        >
                            <FontAwesomeIcon :icon="['far', 'eye']" />
                        </IconButton>
                        <IconButton
                            label=""
                            v-tippy
                            class="controls-btn"
                            :title="editRespondentTooltip(row)"
                            @show.native="() => !!editRespondentTooltip(row)"
                            @click.native="editRespondent(row)"
                        >
                            <FontAwesomeIcon :icon="['far', 'edit']" />
                        </IconButton>
                    </div>
                </StickyTableCell>
            </StickyTableRow>
            <template v-slot:filter>
                <FilterButton
                    id="respondents-filter"
                    @toggleFilter="toggleFilter"
                    :active="filtersApplied"
                />
                <Tray
                    :desktopWidth="380"
                    :left="left"
                    targetElementSelector="#respondents-filter"
                    :open-percentage="filterTrayOpenPercentage"
                    @openPercentageChangeRequest="($e) => setFilterOpenPercentage($e.percentage)"
                >
                    <div class="filter__content__wrapper">
                        <div class="filter__buttons">
                            <Button
                                button-style="secondary"
                                @click.native="clearRespondentsFilters"
                            >
                                {{ $t("cp__respondents_list_filter_clear") }}
                            </Button>
                            <Button @click.native="applyRespondentsFilters">{{
                                $t("general__apply")
                            }}</Button>
                        </div>
                        <div class="filter__input">
                            <legend class="filters__title">
                                {{ $t("cp__respondents_list_filter_invalid_title") }}
                            </legend>
                            <div class="filters_checkbox_container">
                                <Checkbox
                                    v-model="showOnlyInvalid.invalid"
                                    :label="$t('cp__respondents_list_filter_invalid__missing')"
                                    class="filter_checkbox_row__checkbox"
                                />
                                <Checkbox
                                    v-model="showOnlyInvalid.duplicate"
                                    :label="$t('cp__respondents_list_filter_invalid__duplicate')"
                                    class="filter_checkbox_row__checkbox"
                                />
                                <Checkbox
                                    v-model="showOnlyInvalid.queued"
                                    :label="
                                        $t('cp__respondents_list_filter_invalid__recently_sent')
                                    "
                                    class="filter_checkbox_row__checkbox"
                                />
                                <Checkbox
                                    v-model="showOnlyInvalid.unsubscribed"
                                    :label="$t('cp__respondents_list_filter_invalid__unsubscribed')"
                                    class="filter_checkbox_row__checkbox"
                                />
                            </div>
                        </div>
                    </div>
                </Tray>
            </template>
            <template v-slot:actions>
                <div class="table_bottom_actions">
                    <TableActions
                        :numberOfSelectedItems="data.numberOfSelectedItems"
                        :deleteButtonLabel="deleteButtonLabel"
                        :exportButtonLabel="exportButtonLabel"
                        :loading="headerLoading"
                        @deleteSelected="popup = popupOptions.CONFIRM_DELETION"
                        @exportSelected="$emit('exportSelected', { selectedRespondents, columns })"
                    />
                    <div class="action_btn" @click="$emit('requestColsFile')">
                        {{ $t("cp__respondents_list_download_default_columns") }}&nbsp;
                        <FontAwesomeIcon :icon="['far', 'cloud-download']" />
                    </div>
                </div>
            </template>
            <template v-slot:empty>
                <div v-if="!data.rawData.length" class="drag_drop_respondents">
                    <FileDragDrop
                        v-model="respondentsFiles"
                        type=".csv, .xls, .xlsx"
                        :dropAreaCTA="$t('cp__respondents_list_dragdrop_cta')"
                        :dropAreaInfo="$t('cp__respondents_list_dragdrop_info')"
                        :onUploadHandler="uploadRespondentsFile"
                        @confirmUpload="confirmUpload"
                        @deleteFile="deleteFile"
                        @clear="preparedRespondents = []"
                    />
                </div>
                <span v-else-if="!data.data.length">No results.</span>
            </template>
            <template v-slot:pagination-indicator>
                <PaginationIndicator
                    :pages="data.pages"
                    :currentPage="data.currentPage"
                    :currentPageItemCount="data.data.length"
                    :current-page-range-start="data.currentPageRangeStart"
                    :current-page-range-end="data.currentPageRangeEnd"
                    :itemCount="data.itemCount"
                    :itemDescription="
                        $t('cp__respondents_list_table__pagination_indicator__description')
                    "
                    :ofString="$t('cp__respondents_list_table__pagination_indicator__of')"
                    :pageString="$t('cp__respondents_list_table__pagination_indicator__page')"
                />
            </template>
        </StickyTable>
        <ConfirmPopup
            v-if="popup === popupOptions.CONFIRM_DELETION"
            :title="$t('cp__respondents_list_popup_confirm_title')"
            :message="$t('cp__respondents_list_popup_confirm_message')"
            :confirmBtn="$t('cp__respondents_list_popup_delete_button')"
            :cancel-btn="$t('cp__generic__cancel')"
            confirmButtonStyle="negative"
            :disableCancelButton="loading"
            :confirmBtnIsLoading="loading"
            @confirm="handleDelete"
            @cancel="popup = null"
        />
        <Popup :closeAllowed="!loading" v-else-if="popup" @close="closePopup">
            <div class="popup-content">
                <RespondentsUpload
                    v-if="popup === popupOptions.RESPONDENTS_UPLOAD"
                    @showAddRespondent="popup = popupOptions.ADD_RESPONDENT"
                    @close="closePopup"
                    @uploadSuccess="handleUpload"
                />
                <AddRespondent
                    v-if="popup === popupOptions.ADD_RESPONDENT"
                    :columns="columns"
                    :loading="loading"
                    @close="closePopup"
                    @showUpload="popup = popupOptions.RESPONDENTS_UPLOAD"
                    @addRespondent="handleAdd"
                    @updateRespondent="handleEdit"
                    @uploadManually="uploadManually"
                    :selectedRespondent="selectedRespondent"
                />
                <EditColumns
                    v-if="popup === popupOptions.EDIT_COLUMNS"
                    :columns="columns"
                    @close="closePopup"
                    @save="saveHeaderColumns"
                />
                <PreviewInvitation
                    v-if="popup === popupOptions.PREVIEW_INVITATION"
                    :isLoading="loading"
                    @close="closePopup"
                    @send="sendPreviewEmail"
                />
            </div>
        </Popup>
    </div>
</template>

<script>
import { ref, onMounted } from "vue-demi";
import { faEdit, faEye, faCloudDownload } from "@fortawesome/pro-regular-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useTableState } from "@/composables/useTableState";
import PaginationIndicator from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/PaginationIndicator.vue";
import IconButton from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/IconButton.vue";
import StickyTableRow from "@feedbackcompany/feedback-company-vue-components/src/components/molecules/StickyTableRow.vue";
import StickyTableCell from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/StickyTableCell.vue";
import StickyTable from "@feedbackcompany/feedback-company-vue-components/src/components/molecules/StickyTable.vue";
import TableActions from "@feedbackcompany/feedback-company-vue-components/src/components/molecules/TableActions.vue";
import FilterButton from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/FilterButton.vue";
import Button from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Button.vue";
import FileDragDrop from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/FileDragDrop.vue";
import Popup from "@feedbackcompany/feedback-company-vue-components/src/components/organisms/Popup.vue";
import ConfirmPopup from "@feedbackcompany/feedback-company-vue-components/src/components/organisms/ConfirmPopup.vue";
import Checkbox from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/Checkbox.vue";
import Tray from "@feedbackcompany/feedback-company-vue-components/src/components/molecules/Tray.vue";
import { mapState } from "vuex";
import cloneDeep from "lodash.clonedeep";
import { orderBy, uniqueId } from "lodash";
import uploadRespondents from "@/graphql/invitations/uploadRespondents.gql";
import { resolveGraphQLErrors } from "@/helpers/resolveGraphQLErrors";
import RespondentsUpload from "./RespondentsUpload.vue";
import getQuestionnaireFields from "../../graphql/invitations/getQuestionnaireFields.gql";
import checkRespondents from "../../graphql/invitations/checkRespondents.gql";
import previewInvitations from "../../graphql/invitations/previewInvitations.gql";
import AddRespondent from "./AddRespondent.vue";
import EditColumns from "./EditColumns.vue";
import PreviewInvitation from "./PreviewInvitation.vue";

library.add(faEdit, faEye, faCloudDownload);

export default {
    name: "RespondentsList",
    components: {
        ConfirmPopup,
        StickyTableRow,
        StickyTable,
        PaginationIndicator,
        FontAwesomeIcon,
        IconButton,
        Checkbox,
        StickyTableCell,
        TableActions,
        Popup,
        RespondentsUpload,
        AddRespondent,
        EditColumns,
        PreviewInvitation,
        FilterButton,
        Tray,
        Button,
        FileDragDrop,
    },
    data() {
        return {
            columns: [],
            columnNames: [],
            popup: null,
            popupOptions: {
                RESPONDENTS_UPLOAD: "RESPONDENTS_UPLOAD",
                ADD_RESPONDENT: "ADD_RESPONDENT",
                CONFIRM_DELETION: "CONFIRM_DELETION",
                EDIT_COLUMNS: "EDIT_COLUMNS",
                PREVIEW_INVITATION: "PREVIEW_INVITATION",
            },
            invalidCodes: {
                DUPLICATE: "DUPLICATE",
                // Error code for missing value
                INVALID: "INVALID",
                QUEUED: "QUEUED",
                UNSUBSCRIBED: "UNSUBSCRIBED",
            },
            respondentsFiles: [],
            selectedRespondent: null,
            selectedRespondentId: null,
            left: 0,
            loading: false,
            headerLoading: false,
            preparedRespondents: [],
            errorsToDispatch: [],
        };
    },
    computed: {
        ...mapState({
            questionnaireData: (state) =>
                state.ResearchInvitationsStore.steps.SELECT_QUESTIONNAIRE.data,
            respondentsData: (state) =>
                state.ResearchInvitationsStore.steps.CREATE_RESPONDENTS_LIST.data,
            stepId: (state) => state.ResearchInvitationsStore.stepIds.CREATE_RESPONDENTS_LIST,
        }),
        deleteButtonLabel() {
            return this.$t("cp__respondents_list_delete_respondents", {
                selectedItems:
                    this.data.numberOfSelectedItems > 0 ? this.data.numberOfSelectedItems : "",
            });
        },
        exportButtonLabel() {
            return this.$t("cp__respondents_list_export_respondents", {
                selectedItems:
                    this.data.numberOfSelectedItems > 0 ? this.data.numberOfSelectedItems : "",
            });
        },
        isTableValid() {
            return this.data.rawData.length
                ? this.data.rawData.every((item) => (item.error ? item.error.valid : true))
                : true;
        },
        errorCodes() {
            const errorCodes = [];
            this.data.rawData.forEach((item) => {
                if (item.error.errorCode && !errorCodes.includes(item.error.errorCode)) {
                    errorCodes.push(item.error.errorCode);
                }
            });
            return errorCodes;
        },
        errorMsgs() {
            return {
                duplicate: this.$t("cp__respondents_list_error_banner__duplicate"),
                invalid: this.$t("cp__respondents_list_error_banner__missing_email"),
                queued: this.$t("cp__respondents_list_error_banner__queued"),
                unsubscribed: this.$t("cp__respondents_list_error_banner__unsubscribed"),
            };
        },
        selectedRespondents() {
            return this.data.rawData.filter((resp) => resp.isSelected);
        },
    },
    setup() {
        const {
            data,
            setData,
            sort,
            setNoSortHandler,
            search,
            searchTerm,
            setPage,
            selectAll,
            deleteSelected,
            setFilters,
            filtersApplied,
            applyFilters,
            clearFilters,
            setPaginationSize,
        } = useTableState();
        onMounted(() => {});

        setNoSortHandler((computedData) => orderBy(computedData, ["createdAt"], ["desc"]));

        // Filter state
        const filterTrayOpenPercentage = ref(0);
        const setFilterOpenPercentage = (percentage) => {
            filterTrayOpenPercentage.value = percentage;
        };

        const showOnlyInvalid = ref({
            duplicate: false,
            invalid: false,
            queued: false,
            unsubscribed: false,
        });
        const localShowOnlyInvalid = ref({
            duplicate: false,
            invalid: false,
            queued: false,
            unsubscribed: false,
        });

        const applyRespondentsFilters = () => {
            localShowOnlyInvalid.value = cloneDeep(showOnlyInvalid.value);
            let anythingInvalid = false;
            Object.keys(localShowOnlyInvalid.value).forEach((key) => {
                if (localShowOnlyInvalid.value[key] === true) anythingInvalid = true;
            });
            if (anythingInvalid) {
                applyFilters();
            } else {
                clearFilters();
            }
            filterTrayOpenPercentage.value = 0;
        };
        const clearRespondentsFilters = () => {
            clearFilters();
            Object.keys(showOnlyInvalid.value).forEach((key) => {
                showOnlyInvalid.value[key] = false;
            });
            filterTrayOpenPercentage.value = 0;
        };

        // Set filter handler to be used by useTableState
        setFilters([
            ({ error }) => {
                const errorCodes = Object.keys(localShowOnlyInvalid.value);
                const errorCodeBooleans = errorCodes.map((key) => {
                    return localShowOnlyInvalid.value[key] === true
                        ? error.errorCode?.toLowerCase() === key
                        : false;
                });
                return errorCodeBooleans.includes(true);
            },
        ]);

        return {
            data,
            search,
            searchTerm,
            sort,
            setPage,
            setData,
            selectAll,
            deleteSelected,
            filterTrayOpenPercentage,
            setFilterOpenPercentage,
            filtersApplied,
            clearRespondentsFilters,
            applyRespondentsFilters,
            showOnlyInvalid,
            setPaginationSize,
        };
    },
    watch: {
        errorMsgs() {
            if (this.errorsToDispatch.length > 0) {
                this.errorsToDispatch.forEach((err) => {
                    err.message = this.errorMsgs[err.id];
                });
                this.$store.dispatch("removeAllBlockingErrors");
                this.$store.dispatch("setBlockingErrors", this.errorsToDispatch);
            }
        },
        errorCodes(newCodes) {
            if (!this.isTableValid) {
                this.$store.dispatch("removeAllBlockingErrors");
                this.errorsToDispatch = [];
                newCodes.forEach((code) => {
                    switch (code) {
                        case "DUPLICATE":
                            this.errorsToDispatch.push({
                                id: "duplicate",
                                isWarning: true,
                                message: this.errorMsgs.duplicate,
                                icon: "clone",
                            });
                            break;
                        case "INVALID":
                            this.errorsToDispatch.push({
                                id: "invalid",
                                message: this.errorMsgs.invalid,
                            });
                            break;
                        case "QUEUED":
                            this.errorsToDispatch.push({
                                id: "queued",
                                message: this.errorMsgs.queued,
                                icon: "exclamation-circle",
                                color: "#b0521e",
                            });
                            break;
                        case "UNSUBSCRIBED":
                            this.errorsToDispatch.push({
                                id: "unsubscribed",
                                message: this.errorMsgs.unsubscribed,
                                icon: "exclamation-circle",
                                color: "#e2833f",
                            });
                            break;
                        default:
                    }
                });
                this.$store.dispatch("setBlockingErrors", this.errorsToDispatch);
            } else {
                this.errorsToDispatch = [];
                this.$store.dispatch("removeAllBlockingErrors");
            }
        },
        filterTrayOpenPercentage() {
            const filterButton = document.getElementById("respondents-filter");
            this.left = filterButton.offsetLeft - 85;
        },
    },
    methods: {
        uploadManually() {
            this.popup = this.popupOptions.RESPONDENTS_UPLOAD;
        },
        onPageChange(page) {
            this.setPage(page);
        },
        toggleFilter() {
            this.setFilterOpenPercentage(1);
        },
        async getQuestionnaireFields() {
            this.headerLoading = true;
            try {
                const result = await this.$apollo.query({
                    query: getQuestionnaireFields,
                    variables: { questionnaireId: this.questionnaireData.selectedQuestionnaire.id },
                });

                result.data.questionnaireFields.forEach((column) => {
                    const options = [];
                    if (column.options) {
                        column.options.forEach((option) => {
                            options.push({
                                value: option.value,
                                displayValue: option.description,
                            });
                        });
                    }
                    this.columns.push({
                        label: column.description,
                        property: column.name,
                        isRequired: column.isRequired,
                        type: column.type,
                        options,
                        isDefaultColumn: true,
                    });
                    this.columnNames.push(column.name);
                    this.headerLoading = false;
                });
            } catch (errors) {
                await this.$store.dispatch("pushNotification", {
                    type: "error",
                    title: this.$t("cp__generic__error_title"),
                    message: this.$t("cp__generic__error"),
                });
                this.headerLoading = false;
            }
        },
        deleteFile(fileIndex) {
            this.preparedRespondents.splice(fileIndex, 1);
        },
        confirmUpload() {
            this.handleUpload({ data: cloneDeep(this.preparedRespondents.flat(1)) });
            this.preparedRespondents = [];
        },
        async uploadRespondentsFile(file) {
            try {
                const { data } = await this.$apollo.mutate({
                    mutation: uploadRespondents,
                    variables: {
                        respondents: file.originalData,
                        questionnaireId: this.questionnaireData.selectedQuestionnaire.id,
                    },
                });
                this.preparedRespondents.push(data.uploadRespondents);
            } catch (errors) {
                let message = this.$t("cp__generic__error");
                file.error = true;
                file.errorMessage = message;
                if (resolveGraphQLErrors(errors).includes("INVITATION_UPLOAD_LIMIT_EXCEEDED")) {
                    message = this.$t("cp__respondents_list_error_respondent_limit_exceeded");
                }
                await this.$store.dispatch("pushNotification", {
                    type: "error",
                    title: this.$t("cp__respondents_list_error_upload"),
                    message,
                });
            }
            return file;
        },
        handleUpload({ data }) {
            this.addNewColumnsFromFile(data[0].data);
            this.setErrorState(data);
            const uploadDate = new Date();
            this.setTableData([
                ...this.data.rawData,
                ...data.map((item) => {
                    item.data.createdAt = uploadDate;
                    item.data._id = uniqueId();
                    return item.data;
                }),
            ]);
            this.moveToFirstPageAfterAddingRespondents();
        },
        addNewColumnsFromFile(respondent) {
            const allColumns = Object.keys(respondent);
            allColumns.forEach((column) => {
                if (!this.columnNames.includes(column)) {
                    this.columns.push({
                        label: column,
                        property: column,
                        isRequired: false,
                        type: "text",
                        options: [],
                        isDefaultColumn: false,
                    });
                    this.columnNames.push(column);
                }
            });
        },
        async handleAdd(respondent) {
            respondent.createdAt = new Date();
            respondent._id = uniqueId();
            const validatedData = await this.validateRespondents([
                ...this.data.rawData,
                respondent,
            ]);
            this.setTableData(validatedData.map((item) => item.data));
            this.moveToFirstPageAfterAddingRespondents();

            await this.$store.dispatch("pushNotification", {
                type: "success",
                title: this.$t("cp__respondents_list_add_success_title"),
                message: this.$t("cp__respondents_list_add_success_message"),
            });
        },
        async handleEdit(editedRespondent) {
            const validatedData = await this.validateRespondents(
                this.data.rawData.map((respondent) => {
                    if (respondent._id === editedRespondent._id) return editedRespondent;
                    return respondent;
                })
            );

            this.setTableData(validatedData.map((item) => item.data));
            this.closePopup();
        },
        async validateRespondents(respondents) {
            this.loading = true;
            try {
                const result = await this.$apollo.mutate({
                    mutation: checkRespondents,
                    variables: {
                        respondents: this.mapRespondentsForSave(cloneDeep(respondents)),
                        questionnaireId: this.questionnaireData.selectedQuestionnaire.id,
                    },
                });
                this.setErrorState(result.data.checkRespondents);
                result.data.checkRespondents.map((item) => {
                    item.data.createdAt = new Date();
                    item.data._id = uniqueId();
                    return item.data;
                });
                this.loading = false;

                return result.data.checkRespondents;
            } catch (errors) {
                await this.$store.dispatch("pushNotification", {
                    type: "error",
                    title: this.$t("cp__generic__error_title"),
                    message: this.$t("cp__respondents_list_add_error_message"),
                });
                this.loading = false;
            }

            return respondents;
        },
        mapRespondentsForSave(respondents) {
            respondents.forEach((el) => {
                delete el._id;
                delete el.createdAt;
                delete el.isSelected;
                delete el.error;
            });
            return respondents;
        },
        setErrorState(respondents) {
            // eslint-disable-next-line no-return-assign
            respondents.forEach((el) => (el.data.error = el.internal));
        },
        setTableData(data) {
            this.setData(data, {
                paginationSize: 10,
                paginationThreshold: 11,
                paginationEnabled: true,
                selectable: true,
            });
        },
        async handleDelete() {
            this.deleteSelected();
            const validatedData = await this.validateRespondents(this.data.rawData);
            this.setTableData(validatedData.map((item) => item.data));
            this.popup = null;
        },
        async handleNext() {
            try {
                await this.$store.commit("ResearchInvitationsStore/setLoadingState", {
                    loading: true,
                });
                const validatedData = await this.validateRespondents(this.data.rawData);
                this.setTableData(validatedData.map((item) => item.data));

                if (!this.isTableValid) return false;

                await this.$store.commit("ResearchInvitationsStore/setData", {
                    stepId: this.stepId,
                    data: {
                        respondents: this.data.rawData,
                        columns: this.columns,
                        columnNames: this.columnNames,
                    },
                });

                return true;
            } catch (e) {
                await this.$store.commit("ResearchInvitationsStore/setLoadingState", {
                    loading: false,
                });
                return false;
            }
        },
        editRespondent(row) {
            this.selectedRespondent = cloneDeep(row);
            this.selectedRespondentId = row._id;
            this.popup = this.popupOptions.ADD_RESPONDENT;
        },
        previewInvitation(row) {
            this.selectedRespondent = cloneDeep(row);
            this.selectedRespondentId = row._id;
            this.popup = this.popupOptions.PREVIEW_INVITATION;
        },
        closePopup() {
            this.popup = null;
            this.selectedRespondent = null;
            this.selectedRespondentId = null;
        },
        getDisplayName(column, row) {
            const enteredValue = row[column.property];
            return column.type === "text"
                ? enteredValue
                : column.options.find((option) => enteredValue === option.value)?.displayValue;
        },
        getTooltip(error) {
            if (!error || error.valid) return null;
            switch (error.errorCode) {
                case this.invalidCodes.DUPLICATE:
                    return "cp__respondents_list_error_duplicate";
                case this.invalidCodes.QUEUED:
                    return "cp__respondents_list_error_queued";
                default:
                    return "cp__respondents_list_error_invalid";
            }
        },
        saveHeaderColumns(editedColumns) {
            this.columns = editedColumns;
            this.columnNames = [];
            this.columns.forEach((column) => this.columnNames.push(column.property));
            this.popup = null;
        },
        async sendPreviewEmail(event) {
            this.loading = true;
            try {
                const respondent = cloneDeep(this.selectedRespondent);
                delete respondent._id;
                delete respondent.error;
                const { data } = await this.$apollo.mutate({
                    mutation: previewInvitations,
                    variables: {
                        input: {
                            questionnaireId: this.questionnaireData.selectedQuestionnaire.id,
                            email: event,
                            fields: respondent,
                        },
                    },
                });
                if (data.previewInvitations.success === false)
                    throw data.previewInvitations.nessage;
                await this.$store.dispatch("pushNotification", {
                    type: "success",
                    title: this.$t("cp__respondents_list_preview_email_success_title"),
                    message: this.$t("cp__respondents_list_preview_email_success_message", {
                        email: event,
                    }),
                });
                this.loading = false;
            } catch (err) {
                await this.$store.dispatch("pushNotification", {
                    type: "error",
                    title: this.$t("cp__generic__error_title"),
                    message: this.$t("cp__generic__error"),
                });
                this.loading = false;
            }
            this.closePopup();
        },
        moveToFirstPageAfterAddingRespondents() {
            if (this.data.currentSortDirection === "no_sort" && this.data.currentPage !== 1) {
                this.setPage(1);
            }
        },
        editRespondentTooltip(row) {
            return !row.error.errorCode ? this.$t("cp__respondents_list_edit_respondents") : null;
        },
        previewInvitationTooltip(row) {
            return !row.error.errorCode ? this.$t("cp__respondents_list_invitation_preview") : null;
        },
    },
    beforeCreate() {
        this.$store.commit("ResearchInvitationsStore/setNextStepPredicate", {
            stepId: this.$store.state.ResearchInvitationsStore.stepIds.CREATE_RESPONDENTS_LIST,
            predicate: () => {
                if (this.data.rawData.length === 0) return false;
                return this.isTableValid;
            },
        });
        this.$store.commit(
            "ResearchInvitationsStore/setMovingToQuestionnaireRequiresConfirmation",
            {
                stepId: this.$store.state.ResearchInvitationsStore.stepIds.CREATE_RESPONDENTS_LIST,
                predicate: () => !!this.data.data.length,
            }
        );
    },
    beforeDestroy() {
        if (!this.isTableValid) {
            this.$store.dispatch("removeAllBlockingErrors");
        }
    },
    async created() {
        if (this.respondentsData) {
            this.columns = this.respondentsData.columns;
            this.columnNames = this.respondentsData.columnNames;
            this.setTableData(this.respondentsData.respondents);
        } else {
            await this.getQuestionnaireFields();
        }
    },
};
</script>
<style lang="scss" scoped>
@import "~include-media";
@import "../../style_variables/style_variables.scss";

.controls {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    align-items: center;
    &-btn {
        color: $blue;
        font-size: 16px;
        &-sneaky {
            color: transparent;
        }
        &:hover {
            color: $blue_sapphire;
        }
    }
}

.table_row--active,
.table_row:hover {
    .controls-btn-sneaky {
        color: $blue;
    }
}
.table_bottom_actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .action_btn {
        margin-right: 24px;
        font-family: "Open Sans", sans-serif;
        flex-shrink: 0;
        font-weight: 600;
        font-size: 14px;
        color: $blue;
        cursor: pointer;
        &:hover {
            color: $blue_sapphire;
        }
    }
}

.popup-content {
    @include media($isDesktop...) {
        width: 552px;
        max-width: 552px;
    }
}

.filter__content__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 32px 24px;

    .filter__buttons {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
    }

    .filters__title {
        @extend %body1_style;
        font-weight: 700;
        margin: 4px 0 12px;
    }

    .filters_checkbox_container {
        display: grid;
        gap: 12px;
        grid-template-columns: repeat(2, 1fr);
    }

    .filter__input {
        width: 100%;
        display: flex;
        flex-direction: column;

        label {
            @extend %body1_style;
            font-size: 1rem;
            font-weight: 700;
            margin-bottom: 12px;
        }
    }
}

.drag_drop_respondents {
    color: $black;
    padding: 24px 68px 24px 0;
}

.action_button_blue {
    @extend %button_typography_style;
    display: flex;
    align-items: center;
    gap: 8px;
    border: none;
    background-color: $white;
    cursor: pointer;
    padding: 0;
    color: $blue;
    &:hover {
        color: $blue_sapphire;
    }
}
</style>
