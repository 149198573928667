<template>
    <div class="form-container">
        <h1 class="headline5 title">
            {{
                isEdit
                    ? $t("cp__respondents_list_edit_popup_title")
                    : $t("cp__respondents_list_popup_title")
            }}
        </h1>
        <div class="form">
            <section class="form-content">
                <div
                    class="respondent_property"
                    :class="{ respondent_property_select: item.type === 'select' }"
                    v-for="item in columns"
                    :key="item.property"
                >
                    <TextInput
                        v-if="item.type === 'text'"
                        v-model="respondent[item.property]"
                        :label="item.label"
                        enableExternalValidation
                        :externalValidationError="$v.respondent[item.property].$error"
                        :externalValidationErrorMessage="
                            resolveErrorMessage($v.respondent[item.property])
                        "
                        :showRequiredAsterisk="item.isRequired"
                        :disabled="loading"
                    />
                    <Dropdown
                        v-else
                        v-model="respondent[item.property]"
                        :placeholder="item.label"
                        :options="item.options"
                        :showRequiredAsterisk="item.isRequired"
                        enableExternalValidation
                        :externalValidationError="$v.respondent[item.property].$error"
                        :externalValidationErrorMessage="
                            resolveErrorMessage($v.respondent[item.property])
                        "
                        :disabled="loading"
                    />
                </div>
            </section>
            <div class="form-footer">
                <Button
                    v-if="!isEdit"
                    :disabled="loading"
                    buttonStyle="secondary"
                    @click.native="manualUploading"
                >
                    {{ $t("UPLOAD_FILE") }}
                </Button>
                <div class="button-group">
                    <Button buttonStyle="primary" :loading="loading" @click.native="save()">
                        {{
                            isEdit
                                ? $t("cp__respondents_list_popup_save")
                                : $t("cp__respondents_list_popup_load_and_add_new")
                        }}
                    </Button>
                    <Button
                        :disabled="loading"
                        buttonStyle="secondary"
                        @click.native="$emit('close')"
                    >
                        {{ $t("cp__respondents_list_popup_cancel") }}
                    </Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getRespondentSchema } from "@/validation/schemas/respondent";
import { validationMixin, resolveErrorMessage } from "@/validation";
import Dropdown from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/Dropdown.vue";
import Button from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Button.vue";
import TextInput from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/TextInput.vue";

export default {
    name: "AddRespondent",
    mixins: [validationMixin],
    components: {
        Button,
        TextInput,
        Dropdown,
    },
    validations() {
        return getRespondentSchema(this.columns);
    },
    computed: {
        isEdit() {
            return !!this.selectedRespondent;
        },
    },
    props: {
        columns: {
            required: true,
            type: Array,
        },
        selectedRespondent: {
            required: false,
            type: Object,
        },
        loading: {
            required: false,
            type: Boolean,
        },
    },
    data() {
        return {
            respondent: this.getRespondent(),
            file: null,
        };
    },
    methods: {
        resolveErrorMessage,
        getRespondent() {
            return this.selectedRespondent ? this.selectedRespondent : { isSelected: false };
        },
        async save() {
            this.$v.$touch();
            if (!this.$v.$error) {
                if (this.isEdit) {
                    this.$emit("updateRespondent", this.respondent);
                } else {
                    this.$emit("addRespondent", this.respondent);
                }
                this.respondent = this.getRespondent();
                this.$v.$reset();
            }
        },
        manualUploading() {
            this.$emit("uploadManually");
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~include-media";
@import "../../style_variables/style_variables.scss";

.form-container {
    width: 100%;
    height: 100%;
}

.title {
    margin-bottom: 40px;
    margin-top: 12px;
}
.subtitle {
    @extend %body3_style;
    font-size: 0.875rem;
    margin-bottom: 20px;
}

.form {
    width: 100%;
    height: 100%;
}

.form-content {
    margin-bottom: 24px;
    max-height: 50vh;
    overflow-y: auto;
}

.form-footer {
    display: flex;
    justify-content: space-between;
}

.button-group {
    display: flex;
    gap: 10px;
    margin-left: 5px;
}

.respondent_property {
    margin-bottom: 12px;
    margin-right: 24px;

    &:last-child {
        margin-bottom: 0px;
        &.respondent_property_select {
            margin-bottom: 24px;
        }
    }
}
</style>
