<template>
    <div>
        <div class="questionnaire_block selection_confirmation">
            <div>
                <div class="subtitle3">{{ $t("cp__questionnaire_selected") }}</div>
                <div
                    class="body1"
                    :class="{ no_questionnaire_selected: !data.selectedQuestionnaire }"
                >
                    {{
                        !data.selectedQuestionnaire
                            ? $t("cp__questionnaire_no_questionnaire_selected")
                            : data.selectedQuestionnaire.name
                    }}
                </div>
            </div>
            <LoaderCircular :height="36" v-if="isLoading" />
        </div>

        <ErrorNotification
            class="error__no_contents"
            v-if="!selectedItemHasContent && !checkingSelectedItemHasContent"
            :message="$t('cp__questionnaire_fields__error_msg')"
        />

        <div v-if="checkingSelectedItemHasContent" class="checking_has_content_loader">
            <LoaderCircular />
        </div>

        <TreeView
            v-if="data.treeData"
            class="questionnaire_block"
            :items="data.treeData"
            :onClickHandler="selectQuestionnaire"
            :tooltipTitle="$t('cp__questionnaire_id_tooltip')"
        />
    </div>
</template>

<script>
import ErrorNotification from "@feedbackcompany/feedback-company-vue-components/src/components/molecules/ErrorNotification.vue";
import TreeView from "@feedbackcompany/feedback-company-vue-components/src/components/molecules/TreeView.vue";
import LoaderCircular from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/LoaderCircular.vue";
import { mapState } from "vuex";
import getQuestionnaireFields from "@/graphql/invitations/getQuestionnaireFields.gql";
import getQuestionnaireNode from "../../graphql/invitations/getQuestionnaireNode.gql";

export default {
    components: {
        LoaderCircular,
        TreeView,
        ErrorNotification,
    },
    name: "Questionnaire",
    data() {
        return {
            isLoading: false,
            selectedItemHasContent: true,
            checkingSelectedItemHasContent: false,
        };
    },
    computed: {
        ...mapState({
            data: (state) => state.ResearchInvitationsStore.steps.SELECT_QUESTIONNAIRE.data,
            stepId: (state) => state.ResearchInvitationsStore.stepIds.SELECT_QUESTIONNAIRE,
        }),
    },
    methods: {
        async checkHasContents(id) {
            try {
                const result = await this.$apollo.query({
                    query: getQuestionnaireFields,
                    variables: { questionnaireId: id },
                });

                return result.data.questionnaireFields;
            } catch (errors) {
                return null;
            }
        },

        async selectQuestionnaire(node) {
            if (node.leaf) {
                this.checkingSelectedItemHasContent = true;
                const hasContents = await this.checkHasContents(node.id);

                if (this.data.selectedQuestionnaire) {
                    this.data.selectedQuestionnaire.isActive = false;
                }

                if (hasContents === null) {
                    this.data.selectedQuestionnaire = null;
                    this.data.defaultColumns = null;
                    node.isActive = false;
                    this.selectedItemHasContent = false;
                } else {
                    this.data.selectedQuestionnaire = node;
                    this.data.defaultColumns = hasContents;
                    node.isActive = true;
                    this.selectedItemHasContent = true;
                }

                this.checkingSelectedItemHasContent = false;
            } else if (node.children.length === 0) {
                this.isLoading = true;
                const result = await this.$apollo.query({
                    query: getQuestionnaireNode,
                    variables: { categoryNodeId: node.id },
                });
                node.children = this.prepareDisplayData(result.data.questionnaireCategoryNodes);
                this.isLoading = false;
            }
        },
        async getInitialTreeData() {
            this.isLoading = true;
            const result = await this.$apollo.query({
                query: getQuestionnaireNode,
                variables: { categoryNodeId: "rootnode" },
            });
            const treeData = this.prepareDisplayData(result.data.questionnaireCategoryNodes);
            this.$store.commit("ResearchInvitationsStore/setData", {
                stepId: this.stepId,
                data: { ...this.data, treeData },
            });
            this.isLoading = false;
        },
        prepareDisplayData(treeData) {
            return treeData
                .filter((node) => {
                    // Avoid certain questionnaires (and the archive).
                    if (
                        node.name === "Archief" ||
                        (!node.leaf && node.name === "Archief") ||
                        (node.leaf && !node.isActive)
                    ) {
                        return false;
                    }
                    return true;
                })
                .map((node) => {
                    if (!node.leaf) {
                        node.children = [];
                        node.isOpen = false;
                    }
                    node.isActive = false;
                    return node;
                });
        },
    },
    beforeCreate() {
        this.$store.commit("ResearchInvitationsStore/setNextStepPredicate", {
            stepId: this.$store.state.ResearchInvitationsStore.stepIds.SELECT_QUESTIONNAIRE,
            predicate: () => !!this.data.selectedQuestionnaire,
        });

        // preserve step data on component init
        if (this.$store.state.ResearchInvitationsStore.steps.SELECT_QUESTIONNAIRE.data) return;

        this.$store.commit("ResearchInvitationsStore/setData", {
            stepId: this.$store.state.ResearchInvitationsStore.stepIds.SELECT_QUESTIONNAIRE,
            data: {
                selectedQuestionnaire: null,
                treeData: null,
            },
        });
    },
    created() {
        if (this.data.treeData) return;
        this.getInitialTreeData();
    },
};
</script>

<style lang="scss" scoped>
@import "../../style_variables/style_variables.scss";

.questionnaire_block {
    margin-top: 30px;
    padding: 24px;
    border: 1px solid $grey_alabaster;
    border-radius: 4px;

    &.selection_confirmation {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &:last-child {
        margin-bottom: 30px;
        padding: 8px 24px 8px 24px;
    }
}
.no_questionnaire_selected {
    color: $grey_bombay;
}

.error__no_contents {
    margin-top: 30px;
}

.checking_has_content_loader {
    display: flex;
    justify-content: center;
    align-content: center;
    margin-top: 30px;
}
</style>
